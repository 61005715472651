import React from 'react'
import PropTypes from 'prop-types'
import Nav from './header'
import { Link } from 'gatsby'
import { Container, Grid, List, Image, Segment } from 'semantic-ui-react'
import mlwlogowhite from '../images/mlw-white.png'
import './layout.css.less'
import 'semantic-ui-less/semantic.less'
import upicon from '../images/top-sticky-icon.png'

const Layout = ({ children }) => {
	function moveUp() {
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
	}
	return (
		<>
			<Nav />
			<div style={{ marginTop: '5em' }}>
				<main>{children}</main>
				<div
					className="top-sticky-icon"
					onClick={moveUp}
					role="button"
					onKeyUp=""
					tabIndex={0}
				>
					<img src={upicon} alt="" style={{ width: '4em' }} />
				</div>
				<footer>
					<Segment
						style={{
							background: '#309ED3',
							marginBottom: '0',
							borderRadius: '0',
							color: 'white',
							paddingTop: '3em',
							paddingBottom: '3em'
						}}
					>
						<Grid
							className="page-footer"
							inverted
							centered
							stackable
							columns="equal"
							style={{ margin: '0 auto' }}
						>
							<Grid.Column width={5} className="footer-grid">
								<Image
									src={mlwlogowhite}
									className="mlwlogowhite"
									style={{ width: '23em' }}
								/>
							</Grid.Column>
							<Grid.Column width={5} className="footer-grid">
								<span className="inquiry-text">
									For inquiries email at: <br /> subscribe@mylegalwhiz.com
								</span>
								<br />
								<br />
								<span className="inquiry-text">
									For feedback and comments: <br /> feedback@mylegalwhiz.com
								</span>
							</Grid.Column>
							<Grid.Column width={4} className="footer-grid">
								<h4>
									Subscribe to an <br /> Account Today!
								</h4>
								<Link to="/pricing">
									<button className="ui button inverted">GET STARTED</button>
								</Link>
							</Grid.Column>
						</Grid>
					</Segment>
					<Segment
						style={{
							marginTop: '0',
							background: '#19495F',
							borderRadius: '0',
							color: 'white'
						}}
						borderless="true"
					>
						<Container className="sub-footer" style={{ width: '95%' }}>
							<Grid stackable>
								<Grid.Column width={8}>
									<List>
										<List.Item>
											Empowered Legal Education and Practice
										</List.Item>
									</List>
								</Grid.Column>
								<Grid.Column width={8}>
									<List inverted>
										<List.Item className="all-rights-text">
											All Rights Reserved. © {new Date().getFullYear()}
										</List.Item>
									</List>
								</Grid.Column>
							</Grid>
						</Container>
					</Segment>
				</footer>
			</div>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
}

export default Layout
