import { Link } from 'gatsby'
import React from 'react'
import './header.css.less'
import Logo from '../images/logo.png'
import Lea from '../images/lea_blue.png'
import { Dropdown } from 'semantic-ui-react'
function toggleMenu() {
	const navLinks = document.querySelector('.nav-links')
	// const links = document.querySelector(".nav-links li");

	navLinks.classList.toggle('open')
}

const Header = () => {
	return (
		<nav className="ui top fixed borderless menu">
			<div className="ui container ">
				<div className="nav-brand">
					<Link to="/" exact="true">
						<img src={Logo} alt="" className="nav-brand-logo" />
					</Link>
				</div>
				<div className="hamburger">
					<i className="large bars icon" onClick={toggleMenu} />
					<Dropdown
						icon="user circle big"
						simple
						className="user-menu-hamburger"
					>
						<Dropdown.Menu>
							<Dropdown.Item href="//web.mylegalwhiz.com" target="blank">
								Login
							</Dropdown.Item>
							<Dropdown.Item
								href="//web.mylegalwhiz.com/account/sign_up"
								target="blank"
							>
								Sign Up
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<ul className="nav-links">
					<div className="close-hamburger">
						<i className="large close inverted icon" onClick={toggleMenu} />
					</div>
					<Link to="/professional" onClick={toggleMenu}>
						<li>Professional</li>
					</Link>
					<Link to="/business" onClick={toggleMenu}>
						<li>Business</li>
					</Link>
					<Link to="/government" onClick={toggleMenu}>
						<li>Government</li>
					</Link>
					<Link to="/lea" onClick={toggleMenu}>
						<li>
							<span style={{ fontSize: '1.2rem' }}>
								Ask
								<img src={Lea} style={{ height: '0.9em' }} />
							</span>
						</li>
					</Link>
					<Link to="/library" onClick={toggleMenu}>
						<li>Library</li>
					</Link>
					<Link to="/pricing" onClick={toggleMenu}>
						<li>Pricing</li>
					</Link>
					<Link to="/contact" onClick={toggleMenu}>
						<li>Contact</li>
					</Link>
				</ul>

				<Dropdown icon="user circle big" simple className="user-menu">
					<Dropdown.Menu>
						<Dropdown.Item href="//web.mylegalwhiz.com" target="blank">
							Login
						</Dropdown.Item>
						<Dropdown.Item
							href="//web.mylegalwhiz.com/account/sign_up"
							target="blank"
						>
							Sign Up
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</nav>
	)
}

export default Header
